<script setup>
    import { ref,watch,computed,inject } from 'vue'
	import API from '@/api'
	import { useStore } from 'vuex'
	import FormModal from '@/modals/Form.vue'
	
	const store = useStore()
	const moment = inject('moment')
	const helpers = inject('helpers')
	
	const props = defineProps({
        title:String,
		table_titles: Array,
		filters: Array,
        api_url: String,
		data_source:Array,
		sort_by: String,
		modalProps: Object,
		add_new: Boolean,
		add_new_titles: Array,
		empty_state: Object,
		draggable:{
			type:Boolean,
			default:false
		},
		row_click: Function,
		row_click_parameter:{
			type:String,
			default:'id'
		},
		modal_size:{
			type:String,
			default:'md'
		},
		custom_add_new_modal:{
			type:Boolean,
			default:false
		},
		custom_delete:{
			type:Boolean,
			default:false
		},
		filter_row_enabled: {
			type: Boolean,
			default: true,
		},
		search_enabled: {
			type: Boolean,
			default: true,
		},
		action_button_visible: {
			type: Boolean,
			default: true,
		},
    })

	let rand_id = computed(()=>'row_' + (Math.floor(Math.random() * 1000000000)))
	
	const table_titles = ref(props.table_titles)
	
	const ref_delete_row = ref()
	const dataset = ref([])
	const dataset_filtered = ref([])
	const sort_by = ref(props.sort_by)
	const sort_order = ref('desc')
	const data_to_update = ref({})
	
	const isModalOpen = ref(false)
	const isSubmitting = ref(false)
	const submit_error = ref(false)
	const form_modal_props = computed(() => { return {...props.modalProps, ...{title:data_to_update.value.id ? props.add_new_titles[0] : props.add_new_titles[1]}} })

	const display_item = (item,column) => {
		if(!column.id) return 
		
		let text = item
		column.id.split('.').forEach((key)=>{
			text = text[key] ? text[key] : '-'
		})

		if(column.pre_render) {
			text = eval('() => ' + column.pre_render)()
		}
		
		const type = column.type || 'string'
		
		if(type == 'date') {
			return moment(text).format("DD MMMM YYYY HH:mm")
		} else if(type == 'currency') {
			return helpers.format(text,item.currency)
		} else if(type == 'image') {
			return '<img src="' + text + '>'
		} else {
			if(!text) return '-'
			
			return text.toString()
		}
	}

	const fetch_data = (is_refresh = false) => {
		if(props.data_source) {
			dataset.value = props.data_source
			dataset_filtered.value = props.data_source
			return
		}
		if(props.api_url) {
			API.get(props.api_url).then(response => {
				dataset.value = response.data
				dataset_filtered.value = response.data
				if(sort_by.value) {
					onSortChange(sort_by.value, is_refresh)
				}
			})
		}
	}
	fetch_data()
	defineExpose({fetch_data})

	const emit = defineEmits(['add_new_closed','add_new_open','row_deleted','delete_row'])

	const fetch_item_data = (id) => {
		if(props.custom_add_new_modal) {
			emit('add_new_open',id)
			return
		}
		
        API.get(props.api_url+'/' + id)
		.then( response => {
			
			data_to_update.value = prepare_data_for_form(response.data)
            isModalOpen.value = true
        })
    }

	const row_clicked = id => {
		if(!props.row_click) return
		props.row_click(id)
	}

	const onChange = data => {
		
	}

	const onSortChange = (data, is_refresh = false) => {
		if(data == sort_by.value && !is_refresh) {
			sort_order.value = sort_order.value == 'asc' ? 'desc' : 'asc'
		} else {
			sort_by.value = data
		}
		
		//let column = props.table_titles.find(x=>x.id == data)
		dataset_filtered.value = dataset_filtered.value.sort( (a, b) => {

			if(typeof a[data] == 'object') {
				return a[data].filter(x => x[column.filterKey] == column.filterValue   )[0][column.filterField].localeCompare(b[data].filter(x => x[column.filterKey] == column.filterValue   )[0][column.filterField])
			}

			return typeof a[data] == 'number' ? a[data] < b[data] : a[data].localeCompare(b[data])
		})
		if(sort_order.value == 'desc') dataset_filtered.value.reverse()
	}

    const onStatusChange = data => {
		
	}

	const onFilterQueryChange = data => {
		
		if(!data || data == '') {
			dataset_filtered.value = dataset.value
			return	
		}
		
		dataset_filtered.value = dataset.value.filter( dataset_item => {
			let result = props.table_titles
							.filter( column => column.type != 'action' )
							.filter( column => {
								
								let text
								if(column.id.split('.').length > 1) {
									text = dataset_item
									column.id.split('.').forEach((key)=>{
										if(!text[key]) return
										text = text[key] ? text[key] : null
									})
									
								} else {
									text = dataset_item[column.id]
								}
								
								if(!text) return false
								
								return text.toLowerCase().includes( data.toLowerCase() ) 
							})
			return result.length > 0
		})
	}

	// Modal Actions

	const add_data = () => {
		if(!props.custom_add_new_modal) {
			data_to_update.value = {}
        	isModalOpen.value = true
			submit_error.value=false
			return
		}
		emit('add_new_open')
		
    }

    const prepare_data_for_submit = data => {
		
		Object.keys(data).forEach(item => {
			if((typeof data[item] == 'object') && data[item]) {
				if(data[item].length > 0) {
					data[item] = data[item].map(x=>x.value?x.value:x)
				} else {
					data[item] = data[item].value
				}
			}
		})
		return data
	}

	const prepare_data_for_form = data => {
		Object.keys(data).forEach(item => {
			if(typeof data[item] == 'object' && data[item]) {
				//data[item] = data[item].map(x=>{ return {value:x.id, text:x.name }} )
				data[item] = data[item].id
			}
		})
		return data
	}

	const submit = (data)=>{
        data = prepare_data_for_submit(data)
	
		isSubmitting.value = true
		submit_error.value = false;
		
		if(data.id) {
			API.put(props.api_url + '/' + data.id, data).then( response => {
				isModalOpen.value = false
				isSubmitting.value = false
				data_to_update.value = {}
				
				store.dispatch('floating_alerts/addAlert', {
					type:'tip',
					title:'BAŞARILI!',
					description:response.data.message
				})
				
				fetch_data()
			}).catch(error => {
				isSubmitting.value = false
				submit_error.value = error.response.data.message
			})
		} else {
			API.post(props.api_url, data).then( response => {
				isModalOpen.value = false
				isSubmitting.value = false
				data_to_update.value = {}
				
				store.dispatch('floating_alerts/addAlert', {
					type:'tip',
					title:'BAŞARILI!',
					description:response.data.message
				})

				fetch_data()
			}).catch((error)=>{
				isSubmitting.value = false
				submit_error.value = error.response.data.message
			})
		}
		
	}
	

    // Data Actions
	const delete_data = id => {
		if(props.custom_delete) {
			emit('delete_row',id)
			return
		}

		ref_delete_row.value.show({
			title: 'Emin misin?',
			message: 'Bu kaydı silmek üzeresin.',
            submessage: 'Bu işlem geri alınamaz!',
			okButton: 'Sil',
		}).then(result => {
			if (result) {
				API.delete(props.api_url+'/' + id,{})
				.then(response => {
                    dataset_filtered.value = dataset.value.filter(x => x.id != id)
					store.dispatch('floating_alerts/addAlert', {
					type:'tip',
					title:'BAŞARILI!',
					description:response.data.message
				})
					fetch_data(true)
					emit('row_deleted')
                })
                .catch(error => {
					store.dispatch('floating_alerts/addAlert', {
						type:'warning',
						title:'HATA!',
						description:error.response.data.message,
						closeable:false
					})
				})
			} 
		})
    }

	const modal_closed = () => {
        fetch_data()
        isModalOpen.value = false
        setTimeout(() => {
            data_to_update.value = {}
        }, 500)
		emit('add_new_closed')
    }

    watch(() => props.data_source, (data) => {
		if(data) {
			fetch_data()
		}
	},{ immediate: true,deep:true })

</script>


<template>
    <div class="mb-2 text-2xl font-thin uppercase flex items-start text-calypso">{{title}} <UIButton v-if="add_new" text="Yeni" type="dark" icon="plus" class="ml-auto" size="sm" @click="add_data"/></div>
	<UIEmptyState v-if="dataset.length == 0" class="mt-20 w-2/3 mx-auto"
        :title="empty_state.title" 
        :description="empty_state.description"
    />
	<UITable  :search_enabled="search_enabled" :filter_row_enabled="filter_row_enabled" v-if="dataset.length > 0" :sort_order="sort_order" :sort_by="sort_by" :titles="table_titles" @onPageChange="onChange" @onFilterQueryChange="onFilterQueryChange" @onSortChange="onSortChange">
		<template v-slot:filters>
    		<UISelect v-for="filter,x in filters" :key="x" :title="filter.title" :source="filter.data" class="mr-5" @changedata="onStatusChange"/>
  		</template>
		<template v-slot:rows>
			<tr v-if="dataset_filtered.length == 0" class="h-96 "><td colspan="6" class="text-center"><h1>Aradığın kriterlerde bir bilgi yok görünüyor</h1></td></tr>
			
			<tr v-for="item,q in dataset_filtered" :key="'row_' + rand_id + '_' + q" class="hover:bg-teal group" @click="row_clicked(item[row_click_parameter])">
				<UITableCell v-for="column,k in table_titles.filter(x=>x.type!='action')" :key="'cell_' + rand_id + '_' + k" :class="column.class" class="py-3">
					<span  v-if="display_item(item,column).match(/(<[^>]+>|<\/>)/ig)" v-html="display_item(item,column)"></span>
					<span  v-else-if="column.type !== 'image'">{{display_item(item,column)}}</span>
					<img :src="item[column.id]" v-if="column.type == 'image'"/>
				</UITableCell>
				<UITableCell class="truncate text-right py-2" :class="{
					'hidden':!action_button_visible
				}">
                    <UIButton size="sm" type="warning" text="Güncelle" class="duration-300 ml-auto hidden group-hover:inline-block" @click.stop.prevent="fetch_item_data(item[row_click_parameter])"/>
                    <UIButton size="sm" type="danger" text="Sil" class="duration-300 ml-1 hidden group-hover:inline-block" @click.stop.prevent="delete_data(item[row_click_parameter])"/>
                </UITableCell>
			</tr>
		</template>
	</UITable>
	<FormModal :size="modal_size" v-if="modalProps" v-model="data_to_update" :isSubmitting="isSubmitting" :submit_error="submit_error" :modalProps="form_modal_props" :isOpen="isModalOpen" @submit="submit" @modal_closed="modal_closed"/>
	
	<UIConfirm ref="ref_delete_row" :destructive="true"/>
</template>