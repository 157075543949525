<script setup>
    import { computed, ref, watch ,onBeforeMount, onMounted} from "vue"
    import API from "@/api"
    import { useStore } from "vuex"
    import _ from 'lodash'

    const store = useStore()

    const active_field = computed(() => store.state.form.active_field)
    const field = ref(_.cloneDeep(active_field.value))
    
    const update = e => {
        console.log(e.checked)
        field.value.props.show_initials = e.checked ? 1 : 0
    }

    const save = () => {
        console.log(field.value)
        
        API.saveFormField(field.value.id, field.value)
			.then( response => {
				
			})
			.catch( error => {
				store.dispatch("floating_alerts/addAlert", {
					type: "danger",
					title: "HATA!",
					description: error.response.data.message,
				})
			})
	}

</script>

<template>
    <UICheckbox :checked="field.props.show_initials == 1" @change="update" label="Ünvan gösterilsin"/>
    
    <UIButton
        type="submit"
        text="Kaydet"
        class="mt-3"
        @click="save"
    />
</template>