<script setup>
	import { computed, ref, watch, onBeforeMount, onMounted } from "vue";
	import API from "@/api";
	import { useStore } from "vuex";
	import _ from "lodash";

	const store = useStore();

	const form_data = computed(() => store.state.form.active_form);
	const active_field = computed(() => store.state.form.active_field);
	const field = ref({});
	const db_field_columns = ref([]);
	const attendee_lists = ref([]);

	if (form_data.value.project_id) {
		API.get("project/" + form_data.value.project_id + "/attendees/lists").then(
			(response) => {
				attendee_lists.value = response.data.map((list) => {
					return { value: list.id, text: list.name };
				});
			}
		);
	}

	const getDbColumns = () => {
		API.get("project/"+ form_data.value.project_id +"/attendees/column")
        .then( response => {
			db_field_columns.value = response.data == {} ? [] : response.data
		})
	}

    if(form_data.value.project_id) {
		getDbColumns()
	}

	watch(
		() => active_field.value,
		(data) => {
			field.value = _.cloneDeep(active_field.value);
		},
		{ immediate: true, deep: true }
	);

	const room_mates_options = ref([
		{ value: "CHOOSE_ANYONE", text: "Herkes birbirini seçebilir." },
		{
			value: "MACTHED_LIST_CHOOSE",
			text: "Aşağıdaki eşleştirilen listeler birbirini seçebilir.",
		},
	]);

	const new_attendee_list_data = ref({
		field_id: field.value.id,
	});

	const db_column_for_fields = computed(() => {
		let f = [];
		if (Array.isArray(db_field_columns.value)) {
			f = db_field_columns.value.map((arr) => {
				return {
					value: arr.key,
					text: arr.text,
					subtext: "DB Alanı",
				};
			});
		}

		return f;
	});

	const add_attendee_list = () => {
		API.post(
			"form/" + form_data.value.code + "/attendee_list_match",
			new_attendee_list_data.value
		)
			.then((response) => {
				store.dispatch("form/updateField", {
					id: field.value.id,
					field: response.data.data,
				});

				field.value = response.data.data;

				store.dispatch("floating_alerts/addAlert", {
					type: "tip",
					title: "BAŞARILI!",
					description: "Seçenek eklendi.",
				});
				new_option.value = {};
			})
			.catch((error) => {
				store.dispatch("floating_alerts/addAlert", {
					type: "danger",
					title: "HATA!",
					description: error.response.data.message,
				});
			});
	};

	const delete_attendee_list = (id) => {
		API.delete("form/" + form_data.value.code + "/attendee_list_match/" + id)
			.then((response) => {
				store.dispatch("form/updateField", {
					id: field.value.id,
					field: response.data.data,
				});

				field.value = response.data.data;

				store.dispatch("floating_alerts/addAlert", {
					type: "tip",
					title: "BAŞARILI!",
					description: "Seçenek silindi.",
				});
			})
			.catch((error) => {});
	};
</script>

<template>
    <UIFormRow>
        <UISelect
            class="col-span-2"
            :source="db_column_for_fields"
            label="Seçim onaylama kolonu"
            title="Kolon seçiniz"
            display="box"
            v-model="field.verification_column"
        />
    </UIFormRow>
    <hr class="my-5" />
    <UIFormRow>
        <UIRadioGroup
            :options="[
                { value: 0, text: 'Hayır' },
                { value: 1, text: 'Evet' },
            ]"
            :direction="'horizontal'"
            class="font-medium text-gray-500 mb-1"
            label="Seçilen kişiler de görünsün? "
            v-model="field.selected_room_mate_visible"
        />
    </UIFormRow>
    <hr class="my-5" />
    <UIFormRow>
        <UIRadioGroup
            :options="[
                { value: 0, text: 'Hayır' },
                { value: 1, text: 'Evet' },
            ]"
            :direction="'horizontal'"
            class="font-medium text-gray-500 mb-1"
            label="Seçilen kişi onaylamalı mı? "
            v-model="field.room_mate_approve"
        />
    </UIFormRow>
    <hr class="my-5" />
    <UIFormRow>
        <UIRadioGroup
            :options="room_mates_options"
			class="font-medium text-gray-500 mb-1"
            label="Kimler birbirini seçebilir?"
            v-model="field.room_mate_type"
        />
    </UIFormRow>
    <hr class="my-5" />

    <div v-if="field.room_mate_type == 'MACTHED_LIST_CHOOSE'">
        <div
            class="flex gap-3 text-xs font-medium text-gray-500 uppercase mb-2"
            v-if="field.attendee_list_matches.length > 0"
        >
            <div class="grow">Liste</div>
        </div>

        <UIFormRow
            v-for="attende_list_match in field.attendee_list_matches"
            :key="attende_list_match.id"
            class="flex gap-3"
        >
            <div class="grid grid-cols-5 gap-3">
                <div class="grow col-span-2 w-48">
                    {{ attende_list_match.attendee_list_one.name }}
                </div>
                <div class="grow col-span-2 w-48">
                    {{ attende_list_match.attendee_list_two.name }}
                </div>
                <div>
                    <UIButton
                        class="items-center size-sm"
                        size="sm"
                        type="danger"
                        text="Sil"
                        @click="delete_attendee_list(attende_list_match.id)"
                    />
                </div>
            </div>
        </UIFormRow>
        <hr class="my-5" />
        <div
            class="grid grid-cols-4 gap-4 text-xs font-bold text-gray-500 uppercase mb-2"
        >
            <div class="grow col-span-2">Liste Eşleştirmeleri</div>
            <div class="grow"></div>
            <div class="grow"></div>
        </div>
        <UIFormRow class="grid grid-cols-5 gap-4">
            <UISelect
                class="col-span-2"
                :source="attendee_lists"
                title="Liste #1"
                display="box"
                v-model="new_attendee_list_data.attendee_list_id_one"
            />
            <UISelect
                class="col-span-2"
                :source="attendee_lists"
                title="Liste #2"
                display="box"
                v-model="new_attendee_list_data.attendee_list_id_two"
            />
            <UIButton
                class="col-span-1 items-center"
                type="submit"
                text="Ekle"
                @click="add_attendee_list"
            />
        </UIFormRow>
    </div>
</template>
